exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-area-index-js": () => import("./../../../src/pages/area/index.js" /* webpackChunkName: "component---src-pages-area-index-js" */),
  "component---src-pages-beauty-index-js": () => import("./../../../src/pages/beauty/index.js" /* webpackChunkName: "component---src-pages-beauty-index-js" */),
  "component---src-pages-clinic-index-js": () => import("./../../../src/pages/clinic/index.js" /* webpackChunkName: "component---src-pages-clinic-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-techniques-index-js": () => import("./../../../src/pages/techniques/index.js" /* webpackChunkName: "component---src-pages-techniques-index-js" */),
  "component---src-templates-template-area-js": () => import("./../../../src/templates/TemplateArea.js" /* webpackChunkName: "component---src-templates-template-area-js" */),
  "component---src-templates-template-beauty-js": () => import("./../../../src/templates/TemplateBeauty.js" /* webpackChunkName: "component---src-templates-template-beauty-js" */),
  "component---src-templates-template-clinic-js": () => import("./../../../src/templates/TemplateClinic.js" /* webpackChunkName: "component---src-templates-template-clinic-js" */),
  "component---src-templates-template-faq-js": () => import("./../../../src/templates/TemplateFaq.js" /* webpackChunkName: "component---src-templates-template-faq-js" */),
  "component---src-templates-template-other-js": () => import("./../../../src/templates/TemplateOther.js" /* webpackChunkName: "component---src-templates-template-other-js" */),
  "component---src-templates-template-techniques-js": () => import("./../../../src/templates/TemplateTechniques.js" /* webpackChunkName: "component---src-templates-template-techniques-js" */)
}

